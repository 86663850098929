//<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">Notifications</div>
<!--            <AddNewButton :label="$t('Add')" @click="addNewItem" />-->
          </template>
<!--          <template #end>-->
<!--            <Button @click="sendAppointmentNotifications" label="Send appointment notifications" class="p-button-secondary p-button-raised p-mr-2" icon="pi pi-refresh"></Button>-->
<!--            <div class="p-input-icon-left">-->
<!--              <i class="pi pi-search" />-->
<!--              <InputText @input="searchItems($event.target.value, false)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--            </div>-->
<!--          </template>-->
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showButtons="!!actionButtonsCount"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: 'id', title: 'ID',sortable: false},
              {name: 'type', title: 'Type',sortable: false},
              {name: 'order', title: 'Order',sortable: false},
              {name: 'Customer', title: 'Customer',sortable: false},
              {name: 'customer', title: 'Customer phone',sortable: false},
              {name: 'is_processed', title: 'Is processed', sortable: true},
              {name: 'sms_text', title: 'Sms text', sortable: true},
              // {name: 'send_sms', title: 'Sms', sortable: true},
              // {name: 'send_email', title: 'Email', sortable: true},
              // {name: 'send_system', title: 'System', sortable: true},
              {name: 'sms_sent_at', title: 'Sms sent at', sortable: true},
              {name: 'need_to_send_at', title: 'Need to send at', sortable: true},
              {name: 'need_to_send_due', title: 'Need to send due', sortable: true},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>{{ item.id }}</td>
              <td>
                <span v-if="item.type === 3">Appointment reminder</span>
              </td>
              <td>
                <router-link v-if="item.order?.number"
                             :to="{ path: `/orders/${item.order.number}` }"
                             target="_blank">
<!--                  <i class="ti-hummer p-mr-1"></i>-->
                  <div>{{ item.order.number }}</div>
                </router-link>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                 <span v-if="item.user">
                  <span v-if="item.user.type === constants.userTypes.legal_entity">{{ item.user.company_name }}</span>
                  <span v-else>{{ item.user.first_name }} {{ item.user.last_name }}</span>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.user">
                  <span v-if="item.user.type === constants.userTypes.legal_entity">{{ item.user.company_full_phone_number }}</span>
                  <span v-else>{{ item.user.full_phone_number }}</span>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>{{ $t(item.is_processed ? 'Yes' : 'No') }}</td>
              <td>
                <span v-if="item.sms_text">{{ item.sms_text }}</span>
                <i v-else class="ti-minus"></i>
              </td>
<!--              <td>{{ $t(item.send_sms ? 'Yes' : 'No') }}</td>-->
<!--              <td>{{ $t(item.send_email ? 'Yes' : 'No') }}</td>-->
<!--              <td>{{ $t(item.send_system ? 'Yes' : 'No') }}</td>-->
              <td>
                <span v-if="item.sms_sent_at">{{ formatDate(item.sms_sent_at, true)}}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.need_to_send_at"
                      :class="{'warning-color': !item.sms_sent_at && (item.need_to_send_at * 1000 < +Date.now())}">{{ formatDate(item.need_to_send_at, true)}}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.need_to_send_due">{{ formatDate(item.need_to_send_due, true)}}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                <span v-if="item.creator">
                  <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>
                  <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                </span>
              </td>
              <td>
                <DeleteButton @click="confirmItemDelete({id: item.id, name: item.name})"
                              v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
			</div>

      <ConfirmDeleteModal
          :visible="confirmItemDeleteModal"
          @close="closeConfirmItemDeleteModal"
          @delete-item="deleteItem">
      </ConfirmDeleteModal>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import constants from '@/constants'
import formatMixins from "@/mixins/formatMixins";
import datatableMixins from "@/mixins/datatableMixins";
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  // components: { Spinner },
	data() {
		return {
      confirmItemDeleteModal: false,
      itemToDelete: {},
      employees: [],
      fieldsExpand: 'order,user,creator',
      apiLink: 'notification/get-notifications',
      routePath: '/notifications',
      searchData: '',
      sort: {
        column: 'id',
        direction: '-',
      },
		}
	},
  watch: {
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.confirmItemDeleteModal) {
          this.closeConfirmItemDeleteModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
    // this.getEmployees()
	},
  methods: {
    async sendAppointmentNotifications() {
      try {
        const { status, data } = await httpClient.post('notification/send-appointment-notifications')
        console.log(data)
        if (status === 200) {
          // item.warning = !item.warning
        }
      } catch(err) {
        this.showError(err)
      }
    },
    confirmItemDelete(itemToDelete) {
      this.itemToDelete = {...itemToDelete}
      this.confirmItemDeleteModal = true
      if (!this.$store.state.firstLayerIsOpened) {
        this.$store.commit('toggleFirstLayer', true)
      }
    },
    closeConfirmItemDeleteModal() {
      this.itemToDelete = {}
      this.confirmItemDeleteModal = false
      if (this.$store.state.firstLayerIsOpened) {
        this.$store.commit('toggleFirstLayer', false)
      }
    },
    async deleteItem() {
      if (!this.itemToDelete) return false

      const notificationId = this.itemToDelete.id
      try {
        const { status } = await httpClient.post(`notification/delete`, { notificationId })
        if (status === 204) {
          this.getItems()
          this.$toast.add({severity: 'success', detail: this.$t('Data deleted'), life: settings.toastLife});
          this.closeConfirmItemDeleteModal()
        }
      } catch(err) {
        this.showError(err)
      }
    },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.searchData,
            expand: this.fieldsExpand,
          },
        })

        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']

          this.items = data
          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataTableIsLoading = false
        this.isLoading = false
      }
    },
	},
  computed: {
    actionButtonsCount() {
      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
        return 1
      } else {
        return 0
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>